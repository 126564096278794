.App {
  text-align: center;
}

.app-logo {
  pointer-events: none;
  width: 25vw;
  max-width: 194px;
  height: auto;
}

.header a.MuiButton-text {
  font-size: 1.1375rem;
  /* font-weight: bold; */
}

a.landing-page-link {
  color: #373737;
  text-decoration: none;
}

a.landing-page-link:hover {
  background-color: #f5f5f5;
}

.leaflet-container {
  width: 100wh;
  height: 60vh;
  /* z-index: 1; */
}

.leaflet-container .leaflet-control-attribution {
  display: none;
}

.leaflet-div-icon {
  border-radius: 12px;
  background: #4698CB;
  border: 1px solid #fff;
  color: white;
  line-height: 2;
}

.footer-link {
  color: #ccc;
  text-decoration: none !important;
  border-right: 1px solid white;
  padding-right: 8px;
  margin-right: 8px;
}

div .footer-link:last-child  {
  border: none;
}

.footer-link:hover {
  color: white !important;
}

ul.disclaimer-list {
  font-family: Calibri, Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #373737;
}

ul.limits {
  font-family: Calibri, Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #373737;
  margin: 0 0 6px;
}

/* header.MuiAppBar-root div.MuiToolbar-root a.MuiButton-root { */
.header-link {  
  font-size: 1.25rem; /* 1.1375rem; */
  font-weight: 700; /* 500; */
  /* line-height: 1.75; */
  color: #5b5b5d;
  text-decoration: none;
  padding: 0 16px;
  margin: 0 16px;
}

@media (max-width: 768px) {
  .app-logo {
    margin-right: 32px;
  }
  
  .header-link {
    font-size: 16px;
    padding: 0 8px;
    margin: 0 8px;
  }
}

.header-link:hover {
  color: #981d97
}

.header-link.active {
  color: #981d97
}