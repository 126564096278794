div.pharmacy-address-container {
    text-align: left;

    div.pharmacy-title-container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        width: 100%;

        div.pharmacy-title {
            align-items: flex-start;
            display: flex;
        }

        h4 {
            margin-top: 0px;
            margin-bottom: 0;
            font-size: 20px;
            padding-right: 6px;
            color: #4D4D4D;
        }

        h3 {
            margin-top: 4px;
            margin-bottom: 0;
            font-size: 16px;
            padding-right: 12px;
        }

        span {
            padding: 8px 8px 0 8px;
        }
    }

    div.pharmacy-address {
        width: 100%;

        p {
            margin: 0;
            padding: 0 8px;
            color: #4D4D4D;
        }
    }
}

div.pharmacy-card:hover {
    cursor: pointer;
    background-color: #dadbdc;
}

div.pharmacy-card.selected {
    background-color: #bcbdbd;
}